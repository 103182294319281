.W42NZq_app {
  height: 100vh;
  background: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%) 0 0 / 200%;
  justify-content: center;
  align-items: center;
  animation: 10s infinite W42NZq_gradient;
  display: flex;
}

.W42NZq_app > div {
  position: relative;
}

.W42NZq_app h1 {
  color: #f2eee9e6;
  text-shadow: 0 .02em .02em #26323933;
  margin-top: 0;
  font-family: Rubik, sans-serif;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.3;
}

.W42NZq_app h1 span {
  display: block;
}

.W42NZq_social {
  position: absolute;
  top: 1rem;
  right: 0;
}

.W42NZq_social a {
  color: #263239;
  margin-left: 1.5rem;
  display: inline-flex;
}

.W42NZq_social a:hover {
  color: #f2eee9e6;
}

@keyframes W42NZq_gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: none;
}

a:focus {
  outline: thin dotted;
}

a:active, a:hover {
  outline: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
}

mark {
  color: #000;
  background: #ff0;
}

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "“" "”" "‘" "’";
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

button, input, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*# sourceMappingURL=index.098bc435.css.map */
